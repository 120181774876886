export const ASSETS = {
  GLTF_MODEL: {
    key: 'GLTF_MODEL',
    type: 'glb',
    url: `./data/data.glb`,
    src: null,
  },
  GLTF_MODEL_IOS: {
    key: 'GLTF_MODEL_IOS',
    type: 'glb',
    url: `./data/data.glb`,
    src: null,
  },
};
